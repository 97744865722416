<template>
    <div>
        <div class="box">
            <!-- 搜索开始 -->
            <div class="search_box">
                <div style="width: 100%; height: 50px">
                    <el-autocomplete
                            class="inline-input"
                            v-model="searchVal"
                            :fetch-suggestions="querySearch"
                            placeholder="搜索产品"
                            :trigger-on-focus="false"
                            @select="handleSelect"
                            clearable
                            style="width: 80%"
                            @clear="clear"
                    ></el-autocomplete>
                    <el-button type="primary" @click="getSearch">搜索一下</el-button>
                </div>
            </div>
            <!-- 搜索结束 -->
            <!-- 导航栏开始 -->
            <div class="pdt_nav" id="product">
                <div
                        class="nav_img"
                        v-for="(item, index) in navList"
                        :key="index"
                        @click="selectNav(index, item.id)"
                >
                    <!--          <img-->
                    <!--            :src="-->
                    <!--              navIndex == index-->
                    <!--                ? imgUrl + item.selectedImage-->
                    <!--                : imgUrl + item.image-->
                    <!--            "-->
                    <!--          />-->
                    <span :style="navIndex == index ? 'color:#333' : 'color:#999999'">{{
            item.name
          }}</span>
                    <div class="nav_line" v-show="navIndex == index"></div>
                </div>
            </div>
            <!-- 导航栏结束 -->

            <!-- 产品展示开始 -->
            <div class="pro_list">
                <div
                        class="pro_img"
                        v-for="(item, index) in proList"
                        :key="index"
                        @click="goDetail(item.id)"
                >
                    <img :src="imgUrl + item.thumbnail" alt=""/>
                    <span>{{ item.title }}</span>
                </div>
            </div>
            <!-- 产品展示结束 -->
        </div>
    </div>
</template>

<script>
  import {getPro, getProList} from "@/api/product";

  export default {
        data() {
            return {
                navIndex: 0,
                // 导航栏图片
                navList: [],
                // 产品
                proList: [],
                // 参数
                params: {
                    page: 1,
                    limit: 8,
                    cate: 1,
                },
                searchVal: "", // 搜索
                restaurants: [],
                cateId: "",
            };
        },
        mounted() {
            // 产品分类
            this.ProList();
            if (this.$route.query.navIndex) {
                this.navIndex = this.$route.query.navIndex;
                this.getProPage();
            }
        },
        created() {
            let Id = this.$route.query.params;
            this.$nextTick(() => {
                let toElement = document.getElementById(Id);
                //锚点存在跳转
                if (Id) {
                    toElement.scrollIntoView();
                }
            });
        },
        methods: {
            // 去往产品详情
            goDetail(id) {
                this.$router.push({
                    path: "/proDetails",
                    query: {id: id, navIndex: this.navIndex},
                });
            },

            // 产品分类
            ProList() {
                getProList().then((res) => {
                    this.navList = res.data;
                    // console.log("导航栏图片",this.navList)
                    let fieldInfo = [{name: "全部产品", id: null}];
                    this.navList = [...fieldInfo, ...this.navList];
                    if (this.navList[this.navIndex].id) {
                        this.params.cate = this.navList[this.navIndex].id;
                    } else {
                        // delete this.params.cate
                        this.params = {
                            page: 1,
                            limit: 8,
                            cate: "",
                        };
                    }
                    // 产品列表
                    this.getProPage();
                });
            },
            // 产品列表
            getProPage() {
                getPro(this.params).then((res) => {
                    this.proList = [];
                    this.restaurants = [];
                    this.proList = res.data.data;
                    // console.log("产品列表",this.proList)
                    let proListData = res.data.data;
                    proListData.forEach((item) => {
                        this.restaurants.push({value: item.title, id: item.id});
                    });
                });
            },

            selectNav(index, id) {
                this.navIndex = index;
                this.params.cate = id;
                this.cateId = id;
                delete this.params.name;
                this.getProPage();
            },
            querySearch(queryString, cb) {
                var restaurants = this.restaurants;
                // console.log(restaurants)
                var results = queryString
                    ? restaurants.filter(this.createFilter(queryString))
                    : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createFilter(queryString) {
                // console.log("你要的",queryString)
                return (restaurant) => {
                    // return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0); // 精确搜索
                    return (
                        restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0
                    ); // 模糊搜索
                };
            },
            handleSelect(item) {
                // console.log("157",item)
                getPro({name: item.value}).then((res) => {
                    this.proList = res.data.data;
                });
            },
            getSearch() {
                this.params = {
                    page: 1,
                    limit: 8,
                    cate: this.cateId,
                    name: this.searchVal,
                };
                getPro(this.params).then((res) => {
                    this.proList = res.data.data;
                });
            },
            clear() {
                delete this.params.name;
                this.getProPage();
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/style/product.css";

    .search_box {
        position: absolute;
        top: 25px;
        left: 50%;
        margin-left: -25%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pdt_nav {
        display: flex;
        justify-content: space-around;

        .nav_img {
            width: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            img {
                width: 75px;
                height: 75px;
            }

            span {
                font-size: 26px;
                font-weight: bold;
                padding: 15px 0;
            }

            .nav_line {
                width: 50px;
                height: 8px;
                background: #064226;
                border-radius: 4px;
            }
        }
    }

    .pro_list {
        display: flex;
        // justify-content: space-between;

        flex-wrap: wrap;
        margin-top: 75px;

        .pro_img {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 33px;
            margin-bottom: 70px;
            transition: all 0.3s linear;
            border-radius: 8px;

            cursor: pointer;
            /*overflow: hidden;*/
            img {
                width: 260px;
                height: 310px;
                width: 265px;
                height: 241px;
            }

            span {
                font-size: 22px;
                color: #333333;
                margin-top: 10px;
                padding-bottom: 10px;
            }

            &:hover img {
                // transition: 1s;
                // transform: scale(1.05);
                // -ms-transform: scale(1.05);
            }
        }

        .pro_img:hover {
            transform: scale(1.05);
            -ms-transform: scale(1.05);
            transform: translateY(-6px);
            /*box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);*/
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

        }
    }
</style>
