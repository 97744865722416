<template>
    <div>
        <div class="recruit-bg">
            <div class="box">
                <div id="person">
                    <div>
                        <span style="font-size: 38px">岗位招聘</span>
                    </div>
                    <div>
                        <span>Job Recruitment</span>
                    </div>
                </div>
                <div class="person_con">
                    <div class="con_box" v-for="(item, index) in list" :key="index" @click="selectNav(item.id)">
                        <div class="top_img">
                            <el-image
                                    style="width: 100px; height: 100px"
                                    :src="imgUrl + item.icon"
                                    fit="fill">
                            </el-image>
                        </div>
                        <div class="foot_text">
                            <span>{{item.title}}</span>
                        </div>
                    </div>
                    <!--          <div class="con_box" @click="selectNav">-->
                    <!--            <div class="top_img">-->
                    <!--              <img src="../../assets/img/joinUs/5.png" alt="">-->
                    <!--            </div>-->
                    <!--            <div class="foot_text">-->
                    <!--              <span>Java开发工程师</span>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <!--          <div class="con_box" @click="selectNav">-->
                    <!--            <div class="top_img">-->
                    <!--              <img src="../../assets/img/joinUs/10.png" alt="">-->
                    <!--            </div>-->
                    <!--            <div class="foot_text">-->
                    <!--              <span>Python开发工程师</span>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <!--          <div class="con_box" @click="selectNav">-->
                    <!--            <div class="top_img">-->
                    <!--              <img src="../../assets/img/joinUs/11.png" alt="">-->
                    <!--            </div>-->
                    <!--            <div class="foot_text">-->
                    <!--              <span>安卓发工程师</span>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <!--          <div class="con_box" @click="selectNav">-->
                    <!--            <div class="top_img">-->
                    <!--              <img src="../../assets/img/joinUs/13.png" alt="">-->
                    <!--            </div>-->
                    <!--            <div class="foot_text">-->
                    <!--              <span>技术支持</span>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <!--          <div class="con_box" @click="selectNav">-->
                    <!--            <div class="top_img">-->
                    <!--              <img src="../../assets/img/joinUs/6.png" alt="">-->
                    <!--            </div>-->
                    <!--            <div class="foot_text">-->
                    <!--              <span>销售</span>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <!--          <div class="con_box" @click="selectNav">-->
                    <!--            <div class="top_img">-->
                    <!--              <img src="../../assets/img/joinUs/7.png" alt="">-->
                    <!--            </div>-->
                    <!--            <div class="foot_text">-->
                    <!--              <span>商务助理</span>-->
                    <!--            </div>-->
                    <!--          </div>-->

                    <!--          <div class="con_box" @click="selectNav">-->
                    <!--            <div class="top_img">-->
                    <!--              <img src="../../assets/img/joinUs/12.png" alt="">-->
                    <!--            </div>-->
                    <!--            <div class="foot_text">-->
                    <!--              <span>财务会计</span>-->
                    <!--            </div>-->
                    <!--          </div>-->
                    <!--          <div class="con_box" @click="selectNav">-->
                    <!--            <div class="top_img">-->
                    <!--              <img src="../../assets/img/joinUs/14.png" alt="">-->
                    <!--            </div>-->
                    <!--            <div class="foot_text">-->
                    <!--              <span>行政前台</span>-->
                    <!--            </div>-->
                    <!--          </div>-->
                </div>
            </div>
        </div>

        <div class="join-us-bg" id="apply">
            <div class="box">
                <div id="joinUs">
                    <span class="title">加入我们</span>
                </div>
                <el-row :gutter="24" style="margin-top: 70px">
                    <el-col :span="12">
                        <el-row>
                            <span class="join-us-title">福建省震原生物技术有限公司</span>
                            <span class="join-us-title2">办公地址：福建省福州市</span>
                            <span class="join-us-title3"
                            >地址：<span class="join-us-title4"
                            >福建省福州市鼓楼区湖东路99号标力大厦14楼1401室</span
                            ></span
                            >
                            <div class="join-us-title3">
                                电话：<span class="join-us-title4">0591-87323113</span>
                            </div>
                            <div class="join-us-title3">
                                邮箱：<span class="join-us-title4">FJZYSWJS@163.com</span>
                            </div>
                            <div class="join-us-line"></div>
                            <span class="join-us-title-tow-2">办公地址：江西省南昌市</span>
                            <span class="join-us-title3"
                            >地址：<span class="join-us-title4"
                            >江西省南昌市青山湖区北京东路1616号青山湖创业大厦14楼17C-20D</span
                            ></span
                            >
                            <div class="join-us-title3">
                                电话：<span class="join-us-title4">0791-86314313</span>
                            </div>
                            <div class="join-us-title3">
                                邮箱：<span class="join-us-title4">liuzhankeji@sina.com</span>
                            </div>
                            <!--              <el-row-->
                            <!--                style="margin-top: 21px; width: 100%"-->
                            <!--                type="flex"-->
                            <!--                justify="center"-->
                            <!--                align="middle"-->
                            <!--              >-->
                            <!--                <el-image-->
                            <!--                  :src="require('@/assets/img/joinUs/qr_code.png')"-->
                            <!--                  style="width: 120px"-->
                            <!--                ></el-image>-->
                            <!--                <div class="join-us-title5">关注公众号</div>-->
                            <!--              </el-row>-->
                        </el-row>
                    </el-col>
                    <el-col :span="11" style="margin-top: 30px; margin-left: 20px">
                        <el-row type="flex" justify="space-around" align="middle">
                            <el-col :span="10">
                <span class="join-us-input-title"
                >您的姓名<span style="color: red">*</span></span
                >
                                <input type="text" class="join-us-input" v-model="form.name"/>
                            </el-col>

                            <el-col :span="10">
                                <span class="join-us-input-title">应聘岗位</span>
                                <el-select v-model="form.post_id" placeholder="请选择">
                                    <el-option
                                            v-for="item in list"
                                            :key="item.id"
                                            :label="item.title"
                                            :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>

                        <el-row
                                type="flex"
                                justify="space-around"
                                align="middle"
                                style="margin-top: 20px"
                        >
                            <el-col :span="10">
                <span class="join-us-input-title"
                >联系方式<span style="color: red">*</span></span
                >
                                <input
                                        type="number"
                                        value=""
                                        class="join-us-input"
                                        placeholder=""
                                        v-model="form.phone"
                                />
                            </el-col>

                            <el-col :span="10">
                                <span class="join-us-input-title">您的邮箱</span>
                                <input
                                        type="text"
                                        value=""
                                        class="join-us-input"
                                        placeholder=""
                                        v-model="form.email"
                                />
                            </el-col>
                        </el-row>
                        <el-row
                                type="flex"
                                justify="space-around"
                                align="middle"
                                style="margin-top: 20px"
                        >
                            <el-col style="margin-left: 20px; margin-right: 20px">
                                <span class="join-us-input-title">留言备注</span>
                                <textarea
                                        type="text"
                                        value=""
                                        class="join-us-input2"
                                        placeholder=""
                                        v-model="form.remark"
                                />
                            </el-col>
                        </el-row>

                        <el-row type="flex" align="middle" style="margin-top: 50px">
                            <button class="join-us-button" @click="submit">提交</button>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
  import {recruitAdd, recruitList} from "@/api/joinUs";
  // import research  from "../../assets/img/";
    // import sale  from "../../assets/img/";
    // import role  from "../../assets/img/";
    // import whole  from "../../assets/img/header/logo.png";

    export default {
        data() {
            return {
                list: [],
                position: [],
                imgArray: ["1.png", "2.png", "3.png", "4.png"],
                input: "",
                form: {post_id: null},
            };
        },
        created() {
            this.goPosition(this.$route.query.apply, this.$route.query.id);
            this.getRecruitList();

            let Id = this.$route.query.params;
            this.$nextTick(() => {
                let toElement = document.getElementById(Id);
                // console.log("249",toElement)
                //锚点存在跳转
                if (Id) {
                    toElement.scrollIntoView();
                }
            })

        },
        methods: {
            // 表单提交
            submit() {
                if (!this.form.name) {
                    this.$message.error("请填写姓名")
                    return false;
                } else if (!this.form.phone) {
                    this.$message.error("请填写联系方式")
                    return false;
                }
                recruitAdd(this.form).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.message)
                        this.form = {}
                    }
                })

            },

            // 跳转定位
            goPosition(apply, id) {
                if (apply) {
                    this.$nextTick(() => {
                        document.querySelector("#" + apply).scrollIntoView(true);
                    });
                    this.form.post_id = Number(id)
                }
            },

            // 招聘列表
            getRecruitList() {
                recruitList().then((res) => {
                    this.list = res.data.data;
                    // console.log("招聘列表",this.list)
                });
            },

            // 跳转
            selectNav(id) {
                this.$router.push({path: "/details", query: {id: id}});
                // this.$router.push({ path: "/middle" });
                // this.$router.push({ path: "/details" });
            },
        },
    };
</script>

<style lang="scss" scoped>
    // .con_box:hover{
    //    transform: scale(1.05);
    //       -ms-transform: scale(1.05);
    //        transform: translateY(-6px);
    //   box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
    // }
    .recruit-bg {
        background: #f9f9f9;
    }

    #person {
        border-left: 3px solid #326B30;
        padding-left: 20px;
        margin-bottom: 50px;
    }

    .person_con {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .con_box {
            width: 25%;
            /*height: 240px;*/
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;
            cursor: pointer;

            .top_img {
                width: 100%;
                flex: 120px;
                text-align: center;

                img {
                    width: 50%;
                    height: auto;
                    border-radius: 50%;
                }
            }

            .foot_text {
                width: 100%;
                flex: 40px;
                text-align: center;
                line-height: 50px;
            }

            &:hover .el-image {
                transition: 0.3s;
                transform: scale(1.1);
                -ms-transform: scale(1.1);
                /*box-shadow: 0 0 20px rgba(0,0,0,0.5);*/
            }

            &:hover img {
                transition: 0.3s;
                transform: scale(1.1);
                -ms-transform: scale(1.1);
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
            }
        }
    }

    .join-us-bg {
        background: #ffffff;
    }

    .grid-item-bg {
        background: #ffffff;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.15) !important;
    }

    .grid-content {
        height: 150px;
        border-radius: 4px;
        min-height: 36px;
        padding: 20px 0px;
        margin-top: 40px;
    }

    .grid-item-title {
        font-size: 30px;

        font-weight: bold;
        color: #333333;
        line-height: 35px;
    }

    .grid-item-title2 {
        font-size: 20px;
        font-weight: bold;
        color: #666666;
        line-height: 35px;
        margin-top: 40px;
        cursor: pointer;
    }

    .el-row {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
    }

    .join-us-title {
        font-size: 26px;

        font-weight: bold;
        color: #333333;
        line-height: 30px;
    }

    .join-us-title2 {
        font-size: 24px;
        margin-top: 30px;
        margin-bottom: 10px;
        font-weight: 500;
        color: #666666;
        line-height: 30px;
    }

    .join-us-title-tow-2 {
        font-size: 24px;
        margin-bottom: 10px;

        font-weight: 500;
        color: #666666;
        line-height: 30px;
    }

    .join-us-title3 {
        width: 100%;
        font-size: 20px;

        font-weight: bold;
        color: #333333;
        line-height: 30px;
    }

    .join-us-title4 {
        font-size: 20px;

        font-weight: 400;
        color: #666666;
        line-height: 30px;
    }

    .join-us-title5 {
        width: 100%;
        font-size: 20px;

        font-weight: bold;
        text-align: center;
        color: #333333;
        line-height: 30px;
    }

    .join-us-line {
        margin-top: 28px;
        margin-bottom: 28px;
        width: 100%;
        height: 1px;
        background: #d3d3d3;
    }

    .join-us-input-title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #333333;
        line-height: 20px;
    }

    .join-us-input {
        height: 35px;
        margin-top: 10px;
        width: 100%;
        font-size: 18px;
        padding-left: 5px;
        padding-right: 5px;
        background: #f7f7f7;
        border: 1px solid #d3d3d3;
        border-radius: 5px;
    }

    input {
        height: 35px;
        margin-top: 10px;
        width: 100%;
        font-size: 18px;
        padding-left: 5px;
        padding-right: 5px;
        background: #f7f7f7;
        border: 1px solid #d3d3d3;
        border-radius: 5px;
    }

    .join-us-input:focus {
        outline: none !important;
    }

    .el-input__inner {
        background: #f7f7f7 !important;
        border: 1px solid #d3d3d3;
    }

    .el-select .el-input.is-focus .el-input__inner {
        border-color: #d3d3d3 !important;
    }

    .el-input--suffix .el-input__inner {
        border-color: #d3d3d3 !important;
    }

    .join-us-input2 {
        height: 190px;
        margin-top: 10px;
        width: 100%;
        text-align: justify;
        font-size: 18px;
        padding-left: 5px;
        padding-right: 5px;
        background: #f7f7f7;
        border: 1px solid #d3d3d3;
        border-radius: 5px;
        text-indent: 15px;
    }

    .join-us-input2:focus {
        outline: none;
    }

    .join-us-button {
        margin-left: 20px;
        width: 116px;
        height: 46px;
        font-size: 20px;
        color: white;
        background: #064226;
        border-radius: 4px;
        border: 0;
        cursor: pointer;
    }
</style>
