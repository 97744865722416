<template>
  <div>
    <!-- 轮播图开始 -->
    <div class="slideshow">

      <!-- 返回顶部开始 -->
      <el-backtop :bottom="100">
        <div style="{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #89b635;
        border-radius: 20px;}">
          <i class="el-icon-caret-top"></i>
        </div>
      </el-backtop>
      <!-- 返回顶部结束 -->

      <!-- :height="`${bgHeight}px`" -->
      <el-carousel indicator-position="none" :height="`${bgHeight}px`" arrow="never">
        <el-carousel-item v-for="(item, index) in slideshowData" :key="index">
          <img :src="imgUrl + item.img_url" alt="背景图片" />
<!--          <img src="../../assets/img/header/bg.png" alt="背景图片" />-->
          <!-- 标语开始 -->
          <div class="slogan">
            <div class="item">
              <div class="assistant">{{item.max_title}}</div>
              <div class="main">{{item.min_title}}</div>
            </div>
          </div>
          <!-- 标语结束 -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 轮播图结束 -->

    <!-- 导航栏开始 -->
    <div class="nav_wrap">
      <nav>
        <div class="nav_right">
          <img
            @click="selectImg"
            :src="logoImg"
            alt="震原logo"
            class="logo_img"
          />
        </div>
        <div class="nav_left">
          <ul>
            <li
              @click="selectNav(item)"
              class="item itemTitle"
              v-for="(item, index) in navData"
              :key="index"
            >
              <span :class="{ checked: item.path == selectUrl }" v-if=" scrollTopVal < 300 ">{{
                item.name
              }}</span>
              <span :class="{ rollChecked: item.path == selectUrl }" v-else>{{
                item.name
              }}</span>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <!-- 导航栏结束 -->

  </div>
</template>

<script>
import { getSlide } from "@/api/header";
import transparentImg  from "../../assets/img/header/header_log.png";
import colorImg  from "../../assets/img/header/logo.png";
export default {
  name: "Header",
  data() {
    return {
      checked: false,
      navData: [],
      selectUrl: null,
      // 获取当前屏幕的高
      bgHeight:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
      slideshowData: [],
      logoImg:transparentImg,
      scrollTopVal:'',
    };
  },
  computed: {},
  created() {
    // console.log(window.innerHeight ||
    //     document.documentElement.clientHeight ||
    //     document.body.clientHeight)
    // 导航栏
    this.get_nav();
    this.getSlideshow(this.selectUrl.replace("/",""));
  },
  watch: {
    // 监听路由
    $route(to) {
      this.selectUrl = to.path;
      this.getSlideshow(to.path.replace("/",""));
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() { // 监听鼠标滚动
      // 页面滚动距顶部距离
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTopVal = scrollTop
      let navWrap = document.getElementsByClassName("nav_wrap")[0];
      let itemTitle = document.getElementsByClassName("itemTitle")
      // console.log(itemTitle)
      if (scrollTop < 300 ) {
        // navLeft
        // 鼠标上滚  执行的的方法
        navWrap.style.backgroundColor = "rgba(255,255,255,0)";
        navWrap.style.transition = "0.5s";
          for(let i = 0; i < itemTitle.length; i++){
            itemTitle[i].style.color = "#ffffff";
            itemTitle[i].style.transition = "0.5s";
          }
        this.logoImg = transparentImg
      } else {
        // 鼠标下滚  执行的的方法
        navWrap.style.backgroundColor = "rgba(255,255,255,1)";
        navWrap.style.transition = "0.5s";
        this.logoImg = colorImg;
          for(let i = 0; i < itemTitle.length; i++){
            itemTitle[i].style.color = "#326b30";
            itemTitle[i].style.transition = "0.5s";
          }
      }
    },
    // 获取轮播图图片
    getSlideshow(type = "home") {
      getSlide({ type: type }).then((res) => {
        this.slideshowData = res.data.data;
        // console.log("背景",this.slideshowData)
      });
    },

    //   点击logo
    selectImg() {
      this.$router.push("/");
    },
    // 选择导航栏
    selectNav(nav) {
      this.selectUrl = nav.path;
      this.$router.push(nav.path);
    },
    // 获取导航栏
    get_nav() {
      this.navData = this.$router.options.routes[0].children;
      this.selectUrl = this.$router.currentRoute.path;
    },
  },
};
</script>

<style lang="scss" scoped>
// 轮播图
.slideshow {
  position: relative;
  overflow-x: hidden;
  img{
    width: 100%;
    height: auto;
    height: 100%;
    display: block;
    object-fit:cover;
  }
}

// 导航栏
.nav_wrap {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255,255,255,0);
  z-index: 99;
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 1200px;
    height: 100px;
    box-sizing: border-box;
    .nav_right{
      width: 310px;
      height: 60px;
      /*text-align: center;*/
      img {
        width: auto;
        height: 100%;
        cursor: pointer;
      }
    }
    .nav_left {
      flex: 1;
      margin-left: 90px;
      ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 22px;
        font-weight: 500;
        .item{
          padding: 0 20px;
          color: #ffffff;
          &:last-child{
            padding: 0  0  0 20px;
          }
        }
        .item:hover {
          cursor: pointer;
        }
      }
    }
  }
}

// 标语开始
.slogan {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  width: 100%;
  .item {
    width: 1200px;
    margin: auto;
    color: #ffffff;
  }

  .main {
    font-size: 28px;
    margin-top: 30px;
    font-family: 'Alimama_ShuHeiTi_Bold';
  }
  .assistant {
    font-size: 60px;
    font-weight: bold;
    font-family: 'Alimama_ShuHeiTi_Bold';
  }

}
</style>
