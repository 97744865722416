<template>
  <div>
    <div>
      <caseHeader />
    </div>
    <div class="navDetail">
      <div class="navBox">
        <ul>
          <li>
            <span>首页</span>
          </li>
          <li>
            <span>></span>
          </li>
          <li>
            <span @click="back">成功案例</span>
          </li>
          <li>
            <span>></span>
          </li>
          <li>
            <span>案例详情</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="newDetailBox">
      <div class="newTitle">
        <span>{{ title }}</span>
      </div>
      <div class="newTime">
        <span>{{ time }}</span>
      </div>
      <div v-html="content" class="content"></div>
    </div>

    <div class="foot_bot">
      <Footer />
    </div>
  </div>
</template>

<script>
import caseHeader from "@/components/header/caseHeader";
import { getCaseDetail } from "@/api/case";

import Footer from '@/components/footer'
export default {
  components: {
    caseHeader,
    Footer
  },
  data() {
    return {
      title: "",
      time: "",
      content: "",
    };
  },
  created() {
    this.detail(this.$route.query.id);
  },
  methods: {
    // 返回
    back() {
      this.$router.push({ path: "/case" });
    },
    detail(id) {
      getCaseDetail({ id: id }).then((res) => {
        this.title = res.data.title;
        // console.log("成功案例详情",res.data.content)
        this.content = res.data.content;
        this.time = res.data.time;
      });
    },
  },
};
</script>

<style lang="scss" scope>
.content p:nth-child(2){
  // padding-bottom: 24px;
}
.content p:nth-child(n+3){
  width: 100%;
  img{
    width: 100% !important;
    /*height: 669px !important;*/
    height: auto !important;
    object-fit: cover;
  }
}
.content p:nth-child(n+4){
  width: 100%;
  // line-height: 41px;
  img{
    width: 100% !important;
    /*height: 669px !important;*/
    height: auto !important;
    margin: 0 auto;
  }
}
.navDetail {
  display: flex;
  align-items: center;
  /*margin-top: 100px;*/
  height: 60px;
  background: #e9e9e9;
  .navBox {
    width: 1200px;
    margin: auto;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 230px;
      font-size: 18px;
      color: #333333;
    }
  }
}

.newDetailBox {
  width: 1200px;
  width: 840px;
  margin: 55px auto 0;
  font-size: 18px;
  .newTitle {
    font-size: 44px;
    font-weight: 500;
  }
  .newTime {
    margin: 30px 0;
    padding-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    border-bottom: 1px solid #e2e2e2;
  }
}
.foot_bot{
  width: 100%;
}
</style>
