<template>
  <div>
    <div>
      <JoinUsHeader />
    </div>
    <div class="navDetail">
      <div class="navBox">
        <ul>
          <li>
            <span>首页</span>
          </li>
          <li>
            <span>></span>
          </li>
          <li>
            <span @click="back">新闻动态</span>
          </li>
          <li>
            <span>></span>
          </li>
          <li>
            <span>新闻详情</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="newDetailBox">
      <div class="newTitle">
        <span>{{title}}</span>
      </div>
      <div class="newTime">
        <span>{{time}}</span>
      </div>
      <div v-html="content">

      </div>
    </div>
  </div>
</template>

<script>
import JoinUsHeader from "@/components/header/joinUsHeader";
import {getNewDetail} from "@/api/new";

export default {
  components: {
    JoinUsHeader,
  },
  data(){
      return{
          title:'',
          time:'',
          content:''
      }
  },
  created(){
      this.detail(this.$route.query.id)
  },
  methods:{
    // 返回
    back() {
      this.$router.push({path:"/news"})
    },
      detail(id){
          getNewDetail({id:id}).then(res=>{
              this.title = res.data.title
              this.content = res.data.context
              this.time = res.data.date_time
          })
      }
  }
};
</script>

<style lang="scss" scope>
.navDetail {
  display: flex;
  align-items: center;
  margin-top: 100px;
  height: 60px;
  background: #e9e9e9;
  .navBox {
    width: 1200px;
    margin: auto;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 230px;
      font-size: 18px;
      color: #333333;
    }
  }
}

.newDetailBox {
  width: 1200px;
  margin: 55px auto 0;
  .newTitle {
    font-size: 44px;
    font-weight: 500;
  }
  .newTime {
      margin: 30px 0;
      padding-bottom:15px;
      font-size: 20px;
      font-weight: 400;
      color: #999999;
      border-bottom: 1px solid #E2E2E2;
    }
}
</style>