import request from '@/utils/request'


// 案例
export function getCase(){
    return request({
        url:'/project/new',
        method:'post'
    })
}



export function getCaseDetail(data){
    return request({
        url:'/project/detail',
        method:'post',
        data:data
    })
}
