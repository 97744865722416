<template>
  <div>
      <!-- 推荐产品开始 -->
      <ProductItem />
      <!-- 推荐产品结束 -->

      <!-- 核心服务开始 -->
      <ServiceItem />
      <!-- 核心服务结束 -->

      <!-- 成功案例开始 -->
      <CaseItem />
      <!-- 成功案例结束 -->

      <!-- 新闻中心开始 -->
<!--      <NewsItem />-->
      <!-- 新闻中心结束 -->

      <!-- 关于震原开始 -->
      <AboutItem />
      <!-- 关于震原结束 -->

  </div>
</template>

<script>
import AboutItem from '@/components/Home/about'
import ProductItem from '@/components/Home/product'
import CaseItem from '@/components/Home/case'
import ServiceItem from '@/components/Home/service'
// import NewsItem from '@/components/Home/news'
export default {
    name:"Home",
    components:{
        AboutItem,
        ProductItem,
        CaseItem,
        ServiceItem,
        // NewsItem
    }
}
</script>

<style>

</style>
