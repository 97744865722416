import request from '@/utils/request'
//首页的接口


// 关于震原
export function about(){
    return request({
        url:'/about/about',
        method:'post'
    })
}

// 产品分类
export function product(){
    return request({
        url:'/product/cateList',
        method:'post'
    })
}

// 获取案例
export function Case(){
    return request({
        url:'/project/new',
        method:'post'
    })
}

// 获取新闻
export function news(){
    return request({
        url:'/news/news',
        method:'post'
    })
}