import request from '@/utils/request'

// 获取产品列表

export function getProList(){
    return request({
        url:'/product/cateList',
        methods:'post'
    })
}

// 获取产品
export function getPro(data){
    return request({
        url:'/product/list',
        methods:'get',
        params:data
    })
}

// 产品详情
export function getProDetail(data){
    return request({
        url:'/product/detail',
        methods:'get',
        params:data
    })
}

