import Vue from 'vue'
import App from './App.vue'
import router from './router'


// elementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

Vue.use(ElementUI);

// 图片url地址
// let url = 'http://localhost:8087'
let url = 'http://www.zhenyuansw.com'

Vue.prototype.imgUrl = url


// 跳转后返回顶部
router.afterEach(() => {
    window.scrollTo(0,0);
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
