<template>
    <div>
        <div>
            <!--      <JoinUsHeader />-->
            <detailsHeader/>
        </div>
        <!--    <div class="navDetail">-->
        <!--      <div class="navBox">-->
        <!--        <ul>-->
        <!--          <li>-->
        <!--            <span>首页</span>-->
        <!--          </li>-->
        <!--          <li>-->
        <!--            <span>></span>-->
        <!--          </li>-->
        <!--          <li>-->
        <!--            <span @click="back">产品中心</span>-->
        <!--          </li>-->
        <!--          <li>-->
        <!--            <span>></span>-->
        <!--          </li>-->
        <!--          <li>-->
        <!--            <span>产品详情</span>-->
        <!--          </li>-->
        <!--        </ul>-->
        <!--      </div>-->
        <!--    </div>-->
        <div class="coat">
            <div class="proDetailBox">
                <div class="proDetailBox_con">
                    <div class="content">
<!--                        <div class="con_left">-->
<!--                            <img :src="imgUrl + img"/>-->
<!--                        </div>-->
                        <div class="left_img_box" @mouseenter="imgUrlList ? Stop():''" @mouseleave="imgUrlList ? Up() :''">
                            <div class="imgTop" :style="{ height : (bannerHeight - 5) + 'px' }">
                                <span class="imgTitle" v-if="timeImg">{{(timeImgIndex+1) + '/'+ imgUrlList.length  }}</span>
                                <img ref="bannerHeight" style="width: 100%; height: auto;" :src="timeImg" @click="look_detail" alt="" @load="imgLoad" v-if="timeImg">
                                <div v-else style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;">
                                    <el-image style="width: 370px; height: auto;" :src="img" :preview-src-list="[img]"></el-image>
                                </div>
                            </div>
                            <div class="imgBottom" v-if="imgUrlList">
                                <div class="imgBottom_con">
                                    <el-carousel :interval="3000" type="card" height="90px" style="width: 100%" indicator-position="none" :autoplay="autoplayStr" @change="slideChange">
                                        <el-carousel-item v-for="(item,index) in imgUrlList" :key="index">
                                            <div class="imgBox">
                                                <img :src="item" alt="">
                                            </div>
                                        </el-carousel-item>
                                    </el-carousel>
                                </div>
                            </div>
                        </div>
                        <div class="con_right">
                            <div class="title">
                                <span>产品名称：{{ title }}</span>
                            </div>
                            <div class="item" v-html="content" style="color: #56765F;"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="foot_bot">
            <Footer/>
        </div>

        <!-- 图片放大 -->
        <div>
            <el-dialog title="" :visible.sync="dialogVisible" width="700px" :before-close="handleClose">
                <div style="width: 100%;">
                    <img :src="amplifyImgUrl" alt="" style="width: 100%;height: auto;">
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>


    // import JoinUsHeader from "@/components/header/joinUsHeader";
    import detailsHeader from "@/components/header/detailsHeader"

    import {getProDetail} from "@/api/product";
    import Footer from '@/components/footer'

    export default {
        components: {
            detailsHeader,
            // JoinUsHeader,
            Footer
        },
        data() {
            return {
                title: "",
                time: "",
                content: "",
                img: "",
                index: '',
                // imgUrlList: [
                //     'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
                //     'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
                //     'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
                //     'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
                //     'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
                // ],
                imgUrlList:[],
                // 是否自动切换
                autoplayStr:true,
                // 定时器
                new_time:'',
                // 定时器开关
                timeStr:true,
                slideIndex: 0,
                // 时间图片
                timeImg:'',
                timeImgIndex:'',
                //
                dialogVisible:false,
                //
                amplifyImgUrl:"",
                //
                bannerHeight:'',
            };
        },
        created() {
            this.index = this.$route.query.navIndex
            this.detail(this.$route.query.id);
            this.imgLoad();
        },
        methods: {
            // 返回
            back() {
                this.$router.push({path: "/product", query: {navIndex: this.index}})
            },
            detail(id) {
                getProDetail({id: id}).then((res) => {
                    this.title = res.data.title;
                    this.content = res.data.introduction;
                    this.time = res.data.date_time;
                    this.img = this.imgUrl + res.data.thumbnail;
                    if (res.data.slide_show) {
                        res.data.slide_show = res.data.slide_show.split(',')
                        let arr = res.data.slide_show
                        arr.forEach((val, index, arr) => {
                            arr[index] = this.imgUrl + val;
                        })
                    }
                    this.timeImg = res.data.slide_show[0];
                    this.imgUrlList = res.data.slide_show;
                });
            },
            imgLoad() {
                this.$nextTick(() => {
                    this.bannerHeight = this.$refs.bannerHeight.height;
                })
            },
            circulate(event){
                let index = 0;
                let  value = 0;
                if(event || event === 0){
                    value = event
                    this.new_time = setInterval(()=>{
                        value += 1;
                        if (value >= this.imgUrlList.length){
                            value = 0
                            this.timeImg = this.imgUrlList[value]
                            this.timeImgIndex = value
                        } else {
                            this.timeImg = this.imgUrlList[value]
                            this.timeImgIndex = value
                        }
                    },3000)
                } else {
                    this.new_time = setInterval(()=>{
                        index += 1;
                        if (index >= this.imgUrlList.length){
                            index = 0
                            this.timeImg = this.imgUrlList[index]
                            this.timeImgIndex = index
                        } else {
                            this.timeImg = this.imgUrlList[index]
                            this.timeImgIndex = index
                        }
                    },3000)
                }
            },
            slideChange(index) {
                this.slideIndex = index;
                this.timeImgIndex = index
                this.timeImg = this.imgUrlList[index]
                // console.log("this.slideIndex",this.slideIndex)
            },
            // 查看案列详情
            look_detail(){
                this.autoplayStr = false
                this.timeStr = false
                clearInterval(this.new_time)
                this.amplifyImgUrl = this.timeImg
                this.dialogVisible = true
            },
            Stop(){
                clearInterval(this.new_time)
                this.autoplayStr = false
            },
            Up(){
                if(this.timeStr){
                    this.circulate(this.timeImgIndex)
                    this.autoplayStr = true
                }
            },
            handleClose(){
                this.timeStr = true
                this.circulate(this.timeImgIndex)
                this.dialogVisible = false
                this.autoplayStr = true
            },
        },
    };
</script>

<style lang="scss" scope>
    .navDetail {
        display: flex;
        align-items: center;
        /*margin-top: 100px;*/
        height: 60px;
        background: #e9e9e9;

        .navBox {
            width: 1200px;
            margin: auto;

            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 230px;
                font-size: 18px;
                color: #333333;
            }
        }
    }

    .coat {
        width: 100%;

        .proDetailBox {
            width: 1200px;
            margin: 55px auto 0;
            position: relative;
            top: -130px;
            left: 0;

            .proDetailBox_con {

                .content {
                    width: 100%;
                    /*height: 400px;*/
                    background-color: #ffffff;
                    /*margin-bottom: 50px;*/
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
                    display: flex;
                    justify-content: space-between;

                    .con_left {
                        width: 400px;
                        height: 400px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 370px;
                            height: auto;
                        }
                    }
                    .left_img_box{
                        width: 400px;
                        /*height: 400px;*/
                        overflow: hidden;
                        position: relative;
                        .imgTop{
                            width:400px;
                            overflow: hidden;
                            position: relative;
                            min-height: 400px;
                            .imgTitle{
                                position: absolute;
                                top: 15px;
                                right: 15px;
                                display: inline-block;
                                width: 50px;
                                height: 30px;
                                text-align: center;
                                line-height: 30px;
                                font-weight: bolder;
                                background-color: #89b635;
                                border-radius: 15px;
                                color: #FFFFFF;
                            }
                            img{
                                transition: all 0.2s;
                            }
                        }
                        .imgBottom{
                            width: 400px;
                            height: 100px;
                            padding: 5px;
                            box-sizing: border-box;
                            background-color: rgba(255,255,255,0.8);
                            position: absolute;
                            left: 0;
                            bottom: -120px;
                            transition: all 0.2s;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .imgBottom_con{
                                width: 80%;
                                .imgBox{
                                    height: 90px;
                                    text-align: center;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    img{
                                        width: auto;
                                        height: 100%;
                                        clear: both;
                                        display: block;
                                        margin: auto;
                                    }
                                }
                            }

                        }
                        &:hover{
                            .imgBottom{
                                bottom: 0;
                            }
                        }
                    }

                    .con_right {
                        width: 800px;
                        height: 100%;
                        padding: 20px;
                        box-sizing: border-box;

                        .title {
                            width: 100%;
                            height: 60px;
                            line-height: 60px;
                            font-size: 22px;
                            color: #084404;
                        }

                        .item {
                            color: #56765F;
                            font-size: 18px;
                            line-height: 38px;
                        }
                    }
                }

            }

            .newTitle {
                margin: 40px 0;
                padding-bottom: 40px;
                font-size: 32px;
                font-weight: bold;
                color: #333333;
                border-bottom: 1px solid #e2e2e2;
            }

            .parameter {
                font-size: 28px;
                font-weight: 500;
                color: #333333;
            }

            .content {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }

        span:hover {
            cursor: pointer;
        }
    }

    .foot_bot {
        width: 100%;
    }

</style>
