<template>
  <div>
    <!-- 表头开始 -->
    <Header/>
    <!-- 表头结束 -->
    <main>
      <router-view/>
    </main>
    
    <!-- 底部结束 -->
    <Footer />
    <!-- 底部结束 -->
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'

export default {
    components:{
        Header,
        Footer
    }

}
</script>

<style>
.main{
    width: 1200px;
}
</style>