<template>
    <div class="page">
        <!-- 导航栏开始 -->
        <div class="nav_wrap" :class=" scrollTopVal > 300 ? 'nav_wrap_border':'' ">
            <nav>
                <div class="nav_right">
                    <img @click="selectImg" :src="logoImg" alt="震原logo" class="logo_img"/>
                </div>
                <div class="nav_left">
                    <ul>
                        <li @click="selectNav(item)" class="item itemTitle" v-for="(item, index) in navData" :key="index">
                            <span :class="{ checked: item.path == selectUrl }" v-if=" scrollTopVal < 300 ">{{item.name}}</span>
                            <span :class="{ rollChecked: item.path == selectUrl }" v-else>{{item.name}}</span>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <!-- 导航栏结束 -->
        <div class="bg" v-if="slideshowData">
            <img :src="imgUrl + slideshowData[0].img_url" alt="背景图片" v-if="slideshowData[0]"/>
        </div>
    </div>

</template>

<script>
    import transparentImg  from "../../assets/img/header/header_log.png";
    import colorImg from "../../assets/img/header/logo.png";

    import { getSlide } from "@/api/header";

    export default {
        name:'detailsHeader',
        data() {
            return {
                navData: [],
                logoImg:transparentImg,
                scrollTopVal:'',
                pathVal:'',
                slideshowData:[],
            };
        },
        created() {
            // 导航栏
            this.get_nav();
            window.addEventListener("scroll", this.handleScroll, true);
            this.pathVal = this.$route.path;
            this.getSlideshow()
        },
        methods: {
            handleScroll() { // 监听鼠标滚动
                // 页面滚动距顶部距离
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                this.scrollTopVal = scrollTop
                let navWrap = document.getElementsByClassName("nav_wrap")[0];
                let itemTitle = document.getElementsByClassName("itemTitle")
                // console.log(navLeft)
                if (scrollTop == 0) {
                    // navLeft
                    // 鼠标上滚  执行的的方法
                    navWrap.style.backgroundColor = "rgba(255,255,255,0)";
                    navWrap.style.transition = "0.5s";
                    for(let i = 0; i < itemTitle.length; i++){
                        itemTitle[i].style.color = "#ffffff";
                    }
                    this.logoImg = transparentImg
                } else {
                    // 鼠标下滚  执行的的方法
                    navWrap.style.backgroundColor = "rgba(255,255,255,1)";
                    navWrap.style.transition = "0.5s";
                    this.logoImg = colorImg;
                    for(let i = 0; i < itemTitle.length; i++){
                        itemTitle[i].style.color = "#326b30";
                        itemTitle[i].style.transition = "0.5s";
                    }
                }
            },
            //   点击logo
            selectImg() {
                this.$router.push("/");
            },
            // 选择导航栏
            selectNav(nav) {
                this.selectUrl = nav.path;
                this.$router.push(nav.path);
            },
            // 获取导航栏
            get_nav() {
                this.navData = this.$router.options.routes[0].children;
                // console.log("加入我们",this.navData)
                this.selectUrl = this.$router.currentRoute.path;
            },
            getSlideshow(type = "product") {
                getSlide({ type: type }).then((res) => {
                    this.slideshowData = res.data.data;
                    // console.log("背景",this.slideshowData)
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .page{
        // 导航栏
        .nav_wrap_border{
            border-bottom: 1px solid #326b30;
        }
        .nav_wrap {
            position: fixed;
            top: 0;
            width: 100%;
            background-color: rgba(255,255,255,0);
            z-index: 99;
            nav {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: auto;
                width: 1200px;
                height: 100px;
                box-sizing: border-box;
                .nav_right{
                    width: 310px;
                    height: 60px;
                    /*text-align: center;*/
                    img {
                        width: auto;
                        height: 100%;
                        cursor: pointer;
                    }
                }
                .nav_left {
                    flex: 1;
                    margin-left: 90px;
                    ul {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        font-size: 22px;
                        font-weight: 500;

                        .item{
                            padding: 0 20px;
                            color: #ffffff;
                            &:last-child{
                                padding: 0  0  0 20px;
                            }
                        }
                        .item:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .bg{
            width: 100%;
            height: 500px;
            overflow: hidden;
            position: relative;
            img{
                position: absolute;
                left: 0;
                bottom: -100px;
                width: 100%;
                -webkit-filter: blur(4px);
                filter: blur(4px);
            }
        }

    }

</style>
