import request from '@/utils/request'


// 岗位招聘列表
export function recruitList(){
    return request({
        url:'/recruit/list',
        method:'post'
    })
}

// 招聘详情
export function detail(data){
    return request({
        url:'/recruit/detail',
        method:'post',
        data:data
    })
}


// 加入我们
export function recruitAdd(data){
    return request({
        url:'/recruit/add',
        method:'post',
        data:data
    })
}
