import request from '@/utils/request'


// 关于震原
export function getSlide(data){
    return request({
        url:'/home/slide_list',
        method:'post',
        data:data
    })
}
