<template>
  <div>
    <div class="box">
      <div class="aboutHeader">
        <div>
          <span style="font-size: 38px;color: #333333;font-weight: bolder;">关于震原</span>
        </div>
        <div>
          <span style="font-size: 18px;color: #999999;">About Us</span>
        </div>
      </div>
      <div class="aboutFooter">
        <div class="left">
<!--          <img src="../../assets/img/home/about/about_bg.png" alt="">-->
          <img :src="imgUrl + img" alt="" />
        </div>
<!--        <div class="right">-->
<!--          <div class="right_box" style="font-size: 24px;">-->
<!--            <span style="color: #333333;">震原生物 · </span><span style="color: #326b30;">致力于研究动植物产品</span>-->
<!--          </div>-->
<!--          <div class="right_box">-->
<!--            <span>福建省震原生物技术有限公司（简称：震原生物）拥有农业信息化与食品质量安全领域核心技术产品、高水平的技术骨干团队，为客户提供的产品与服务以技术领先，安全可靠的特点著称，在业内得到广泛应用。</span>-->
<!--          </div>-->
<!--          <div class="right_box">-->
<!--            <span>震原生物长期致力于农业行业软件产品与终端应用设备的研发，为客户提供领先的信息化产品与应用解决方案。公司拥有规模化的技术开发与项目管理团队，由农业与信息技术领域内资深的行业专家和高级工程师组成，具备丰富的大型软件系统开发、系统集成、电信增值业务的项目实施经验与管理能力。</span>-->
<!--          </div>-->
<!--          <div class="right_box">-->
<!--            <span>震原生物本着“资源共享，服务于民，方便客户”的原则，同时还代理多家公司的色谱柱、离心机、微量移液器等实验仪器以及和农业相关的设备、耗材。</span>-->
<!--          </div>-->
<!--        </div>-->
        <div class="right" v-html="aboutDescribe">

        </div>
      </div>
<!--      <div class="wrap">-->
<!--        <div class="left">-->
<!--          <div>-->
<!--            <span class="title">{{title}}</span>-->
<!--            <div class="line"></div>-->
<!--          </div>-->
<!--          <div>-->
<!--            <span>-->
<!--              {{ aboutDescribe | ellipsis }}-->
<!--            </span>-->
<!--          </div>-->
<!--          <div class="moreBtn" @click="goAbout">-->
<!--            <span class="btn_font">查看更多</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="right">-->
<!--          <img :src="imgUrl + img" alt="" />-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import {about} from '@/api/home'
export default {
  name: "AboutItem",
  data() {
    return {
      //
      aboutDescribe: `福建省震原生物技术有限公司（简称：震原生物）拥有农业信息化与食品质量安全领域核心技术产品、高水平的技术骨干团队，为客户提供的产品与服务以技术领先安，全可靠的特点著称，在业内得到广泛应用
                            震原生物长期致力于农业行业软件产品与终端应用设备的研发，为客户提供领先的信息化产品与应用解决方案。公司拥有规模化的技术开发与项目管理团队，由农业与信息技术领域内资深的行业专家和高级工程师组成，具备丰富的大型软件系统开发、系统集成、电信增值业务的项目实施经验与管理能力。
                            震原生物本着“资源共享，服务于民，方便客户”的原则，同时还代理多家公司的色谱柱、离心机、微量移液器等实验仪器以及和农业相关的设备、耗材。`,
      title:'公司简介',
      img:''
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 100) {
        return value.slice(0, 100) + "...";
      }
      return value;
    },
  },
  created(){
    this.get_about()
  },
  methods:{

    // 查看更多

    goAbout(){
      this.$router.push("/about")
    },

    // 关于震原
    get_about(){
      about().then(res =>{
        this.aboutDescribe = res.data.introduction
        this.title = res.data.title
        this.img = res.data.image
      })
    }
  }

};
</script>

<style lang="scss" scoped>
@import "../../assets/style/zy_websit.scss";
.aboutHeader{
  border-left: 3px solid #326B30;
  padding-left: 20px;
  margin-bottom: 50px;
}
.aboutFooter{
  display: flex;
  justify-content: space-between;
  font-size: 0;
  height: 380px;
  box-sizing: border-box;
  .left {
    width: 49%;
    height: 100%;
    img{
      width: 100%;
      height: auto;
    }
  }
  .right {
    width: 49%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 20px;
    .right_box{
      width: 100%;
      // &:nth-child(1){
      //   font-weight: bolder;
      // }
      // &:nth-child(2){
      //   text-indent: 2rem;
      // }
      // &:nth-child(3){
      //   text-indent: 2rem;
      // }
      // &:nth-child(4){
      //   text-indent: 2rem;
      // }
    }
  }
}

.wrap {
  display: flex;
  justify-content: space-between;
  font-size: 0;
  height: 550px;
  box-sizing: border-box;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 610px;
    font-size: 20px;
  }
  .right {
    img {
      width: 430px;
      height: 550px;
    }
  }
}

  .recommend_box{
      width: 100%;
      display: flex;
      flex-direction: column;
      .recommend_title{
        border-left: 3px solid #326B30;
        padding-left: 20px;
        /*height: 75px;*/
      }
    .recommend_con{
      display: flex;
      justify-content: space-between;
      .content_box{

      }
    }
  }
</style>
