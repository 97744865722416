import Cookies from 'js-cookie'

const tokenKey = 'token'

// 获取token的操作
export function getToken(){
    return Cookies.get(tokenKey)
}

// 删除token
export function removeToken(){
    return Cookies.remove(tokenKey)
}

// 设置token
export function setToken(token){
    return Cookies.set(tokenKey,token)
}