<template>
  <div>
    <div class="details_head" style="background-color: #064226">
      <JoinUsHeader />
    </div>

    <div class="details-bg">
      <div class="navDetail">
        <div class="navBox">
          <ul>
            <li>
              <span>首页</span>
            </li>
             <li>
              <span>></span>
            </li>
            <li>
              <span @click="goJoinUs">选择岗位</span>
            </li>
             <li>
              <span>></span>
            </li>
            <li>
              <span>岗位详情</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="box">
        <el-row type="flex" justify="center" align="middle">
          <el-col :span="20">
            <span class="title">招聘岗位：{{title}}</span>
          </el-col>
          <button class="details-button" @click="goJoinUs">岗位申请</button>
        </el-row>
        <div class="details-line"></div>

        <el-col style="margin-left: 10px; margin-right: 10px">
          <div
            style="margin-top: 30px"
          >
            <el-col :span="24">
              <li class="details-content" v-html="content"></li>
            </el-col>
          </div>
        </el-col>

        <!-- <el-col style="margin-left: 10px; margin-right: 10px; margin-top: 60px">
          <div class="details-title">任职要求：</div>
          <div
            style="margin-top: 30px"
            v-for="(item, index) in list2"
            :key="index"
          >
            <el-col :span="24">
              <li class="details-content">{{ item }}</li>
            </el-col>
          </div>
        </el-col> -->

      </div>
    </div>
  </div>
</template>

<script>
import JoinUsHeader from "@/components/header/joinUsHeader";
import { detail } from "@/api/joinUs";

export default {
  components: {
    JoinUsHeader,
  },
  data() {
    return {
      title:'',
      content:'',
      list: [
        "1.参与公司各项目中的前端需求变现，负责web前端、移动APP应用前端设计和研发工作；",
        "2.与后端人员配合，高质量完成前端开发工作；",
        "1.根据项目需求，分析并提出合理的页面前端结构解决方案;",
      ],
      list2: [
        "1.专科及以上，专业不限；",
        " 2.良好的沟通协调能力，有一定的文字功底； ",
        "3.精通HTML5/CSS3/Javascript等主",
        "4精通jQuery、bootstrip等主流的js框架和库，并能够对其特性和应用有深入的了解；",
        "5.熟悉ajax/xml/json等网络通信技术和数据交换格式；",
        "6.熟悉Uni-APP框架，git工具；",
      ],
    };
  },
  created() {
    this.getDetail(this.$route.query.id)
  },
  methods:{
    // 岗位申请
    goJoinUs(){
      // this.$router.push({path:"/middle",query:{middle:'middle',id:this.$route.query.id}})
      this.$router.push({path:"/joinUs",query:{apply:'apply',id:this.$route.query.id}})
    },
    getDetail(id){
      let data = {
        id:id
      }
      detail(data).then(res=>{
        this.title = res.data.title
        this.content = res.data.content
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.details_head{
  /*width: 100%;*/
  /*height: 900px;*/
  /*background: url("../../assets/img/joinUs/9.png") no-repeat center center;*/
  /*background-size: 100% 100%;*/
}

.details-bg {
  /*margin-top: 100px;*/
  background: #ffffff;
}

.details-line {
  width: 100%;
  margin: 0 10px 30px;
  height: 2px;
  background: #e2e2e2;
}

.details-button {
  margin-left: 20px;
  width: 158px;
  height: 46px;
  font-size: 20px;
  font-family: OPPOSans;
  font-weight: 400;
  color: white;
  border: 0;
  background: #064226;
  cursor: pointer;
}
.details-title {
  font-size: 28px;
  font-family: OPPOSans;
  font-weight: 500;
  color: #666666;
  line-height: 35px;
}

.details-content {
  font-size: 20px;
  font-family: OPPOSans;
  font-weight: 400;
  color: #666666;
  line-height: 35px;
}

.navDetail {
  display: flex;
  align-items: center;
  height: 60px;
  background: #e9e9e9;
  .navBox {
    width: 1200px;
    margin: auto;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 230px;
      font-size: 18px;
      color: #333333;
    }
  }
}
</style>
