import request from '@/utils/request'


// 最近动态
export function newsUp(){
    return request({
        url:'/news/news',
        method:'post',
    })
}

// 全部新闻
export function allNews(data){
    return request({
        url:'/news/list',
        method:'post',
        data:data
    })
}

// 新闻详情
export function getNewDetail(data){
    return request({
        url:'/news/detail',
        method:'post',
        data:data
    })
}