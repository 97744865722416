import axios from "axios";
import {
    getToken
} from '@/utils/cookies'
import {
    Message
} from 'element-ui'


axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'


// 创建一个axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 10000
})

// 请求拦截器
service.interceptors.request.use(config => {
    // 设置token请求头
    if (getToken()) {
        config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return config;
}, error => {
    console.error(error);
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 响应状态码
    const code = res.data.code;
    if (code == 200) {
        return res.data
    } else {
        Message.error({
            message: res.data.msg
        })
    }
}, error => {
    let {
        message
    } = error;
    if (message == "Network Error") {
        message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
        message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
        message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message.error({
        message: message
    })
})

export default service