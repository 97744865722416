<template>
  <div class="productWrap">
    <div class="box">
      <!--      <div class="productHeader">-->
      <!--        <div>-->
      <!--          <span class="title">产品分类</span>-->
      <!--          <div class="line"></div>-->
      <!--        </div>-->
      <!--        <div class="moreBtn" @click="goPro">-->
      <!--          <span class="btn_font">查看更多</span>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="productDetail">-->
      <!--        <ul>-->
      <!--          <li class="item" v-for="(item,index) in list" :key="index">-->
      <!--            <div class="i_product">-->
      <!--              <span class="product_title">{{item.name}}</span>-->
      <!--              <img :src="imgUrl + item.index_image" alt="" class="indexImage"/>-->
      <!--              <img class="go_product" src="../../assets/img/home/product/2.png" alt="go_product" @click="goProduct(index)">-->
      <!--            </div>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </div>-->
      <div class="recommend_box">
        <div class="recommend_title">
          <div>
            <span style="font-size: 38px; color: #333333; font-weight: bolder"
              >推荐产品</span
            >
          </div>
          <div>
            <span style="font-size: 18px; color: #999999"
              >Featured Products</span
            >
          </div>
        </div>
        <div class="recommend_con">
          <div
            class="content_box"
            v-for="(item, index) in proList"
            :key="index"
            @click="goDetail(item.id)"
          >
            <div class="box_top">
              <!-- :style="{'backgroundImage': 'url('+imgUrl + item.thumbnail+')'}" -->
              <img :src="imgUrl + item.thumbnail" alt="" />
              <!-- <div class="box_location"></div> -->
            </div>
            <div class="box_foot">
              <div class="box_foot_left">
                <div>
                  <span style="font-size: 20px">
                    {{ item.title }}
                  </span>
                </div>
                <div>
                  <span style="font-size: 16px">
                    <!--                    {{item.introduction}}-->
                  </span>
                </div>
              </div>
              <div class="box_foot_right">
                <span> → </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { product } from "@/api/home";
import { getPro } from "@/api/product";
export default {
  name: "ProductItem",
  data() {
    return {
      list: [],
      imgSrc: "",
      // 参数
      params: {
        hot: 1,
      },
      // 推荐产品
      proList: [],
    };
  },
  created() {
    this.get_pro();
    this.getProPage();
  },
  methods: {
    // 产品列表
    getProPage() {
      getPro(this.params).then((res) => {
        // console.log("推荐产品",res)
        this.proList = res.data.data;
      });
    },

    // 去往对应的tab
    goProduct(index) {
      this.$router.push({ path: "/product", query: { navIndex: index } });
    },

    // 查看更多
    goPro() {
      this.$router.push("/product");
    },
    // 获取产品
    get_pro() {
      product().then((res) => {
        this.list = res.data;
      });
    },
    // 去往产品详情
    goDetail(id) {
      this.$router.push({
        path: "../../proDetails",
        query: { id: id, navIndex: this.navIndex },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/style/zy_websit.scss";
.content_box:hover {
  transform: translateY(-6px);
  // box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
}
.productWrap {
  background-color: #f9f9f9;
  .productHeader {
    display: flex;
    justify-content: space-between;
  }
  .productDetail {
    margin-top: 100px;
    ul {
      display: flex;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 300px;
        height: 360px;
        border-top: 5px solid #e9e9e9;
        background-color: #fff;
        .i_product {
          display: flex;
          flex-direction: column;
          align-items: center;
          .product_title {
            font-size: 22px;
            font-weight: 600;
          }
          .indexImage {
            width: 250px;
            height: 250px;
          }
          .go_product {
            display: none;
          }
        }
      }
      .item:hover {
        position: relative;
        top: -20px;
        height: 360px;
        border-top: 5px solid $mainColor;
        z-index: 99;
        .go_product {
          width: 25px;
          height: 15px;
          display: block;
          cursor: pointer;
        }
      }
    }
  }
  .recommend_box {
    width: 100%;
    height: 868px;
    display: flex;
    flex-direction: column;
    .recommend_title {
      border-left: 3px solid #326b30;
      padding-left: 20px;
      margin-bottom: 50px;
      /*height: 75px;*/
    }
    .recommend_con {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .content_box {
        width: 30%;
        height: 360px;
        margin-bottom: 30px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        transition: all 0.3s linear;
        .box_top {
          flex: 0.8;
          width: 100%;
          overflow: hidden;
          text-align: center;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .box_location {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #fff;
          }
        }
        .box_foot {
          flex: 0.2;
          width: 100%;
          padding: 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          border-top: 1px solid #f7f7f7;
          .box_foot_left {
            display: flex;
            flex-direction: column;
            div {
              &:nth-child(1) {
                color: #333333;
              }
              &:nth-child(2) {
                color: #999999;
              }
            }
          }
          .box_foot_right {
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-weight: bolder;
            color: #ffffff;
            background-color: #efefef;
            border-radius: 15px;
          }
        }
        &:hover .box_top img {
          transition: 1s;
          transform: scale(1.02);
          -ms-transform: scale(1.02);
        }
        &:hover .box_foot {
          transition: 1s;
          background-color: #326b30;
          color: #ffffff;
        }
        &:hover .box_foot_left {
          transition: 1s;
          div {
            &:nth-child(1) {
              color: #ffffff;
            }
            &:nth-child(2) {
              color: #ffffff;
            }
          }
        }
        &:hover .box_foot_right {
          color: #326b30;
          background-color: #ffffff;
          transition: 1s;
        }
      }
    }
  }
}
</style>
