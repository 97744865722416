<template>
  <div class="caseWrap">
    <div class="box">
<!--      <div class="caseHeader">-->
<!--        <div>-->
<!--          <span class="title">成功案例</span>-->
<!--          <div class="line"></div>-->
<!--        </div>-->
<!--        <div class="moreBtn" @click='goCase'>-->
<!--          <span class="btn_font">查看更多</span>-->
<!--        </div>-->
<!--      </div>-->

      <div class="caseHeader">
        <div class="caseHeader_title">
          <div>
            <span style="font-size: 38px;color: #333333;font-weight: bolder;">成功案例</span>
          </div>
          <div>
            <span style="font-size: 18px;color: #999999;">Success Stories</span>
          </div>
        </div>
      </div>

      <!-- 轮播图开始 -->
      <div class="slideshow">
        <el-carousel
          :interval="4000"
          type="card"
          height="450px"
          indicator-position="outside"
          @change="slideChange"
        >
          <el-carousel-item v-for="(item,index) in list" :key="index">
            <div class="slideshowWrap">
              <img :src="imgUrl + item.thumbnail" alt=""  @click="slideIndex == index ? look_detail(item) : '' "/>
                <div class="slideshowBox">
                    <p class="title">{{item.title}}</p>
                    <p class="introduction">{{item.introduction}}</p>
                </div>

<!--              <div class="btn" v-show="slideIndex == index">-->
<!--                <span>{{item.title}}</span>-->
<!--              </div>-->
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 轮播图结束 -->
    </div>
  </div>
</template>

<script>
import { Case } from "@/api/home";
export default {
  name: "CaseItem",
  data() {
    return {
      slideIndex: 0,
      //轮播图数据
      list:[]
    };
  },
  created() {
    this.get_case();
  },
  methods: {
    // 跳转到案例
    goCase(){
      this.$router.push("/case")
    },

    // 获取案例
    get_case() {
      Case().then(res => {
        this.list = res.data
          // console.log("成功案例",this.list)
      })
    },

    slideChange(index) {
      this.slideIndex = index;
    },

    // 查看案列详情
    look_detail(item){
      this.$router.push({path:"/caseDetail",query:{id:item.id}})
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/style/case.css";
@import "../../assets/style/zy_websit.scss";

.caseHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .caseHeader_title{
    border-left: 3px solid #326B30;
    padding-left: 20px;
  }
}
.slideshow {
  margin-top: 80px;
  .slideshowWrap {
      width: 100%;
      height: 403px;
      position: relative;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    img{
        width: 100%;
        height: 100%;
    }
      .slideshowBox{
          width: 100%;
          height: 100px;
          box-sizing: border-box;
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 40px 10px 0;
          background-image: linear-gradient(to top  , #000000 , transparent);
          .title{
              color: #ffffff;
              font-weight: bolder;
              font-size: 20px;
          }
          .introduction{
              color: #ffffff;
              display: inline-block;
              width: 500px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              /*font-weight: bolder;*/
              font-size: 16px;
          }
      }

  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding:0 30px;
    height: 40px;
    border-radius: 21px;
    background-color: $mainColor;
    color: #fff;
    font-size: 22px;
    font-weight: 600;
  }
}
</style>

