<template>
    <div class="case-bg">
        <div class="box">
            <!--      <div>-->
            <!--        <span class="title" >成功案例</span>-->
            <!--      </div>-->

            <div class="case_title">
                <div>
          <span style="font-size: 38px; color: #333333; font-weight: bolder"
          >成功案例</span
          >
                </div>
                <div>
                    <span style="font-size: 18px; color: #999999">Success Stories</span>
                </div>
            </div>
            <div class="caseListing">
                <div class="caseList_con" v-for="(item, index) in list" :key="index" @click="look_detail(item)">
                    <div class="caseBox">
                        <div class="caseBox_img">
                            <img :src="imgUrl + item.thumbnail" alt=""/>
                        </div>
                        <div class="caseText">
                            <p>
                                {{ item.title }}
                            </p>
                            <p>
                                {{ item.introduction }}
                            </p>
                        </div>
                        <div class="goCase">
                            <span>查看详情</span>
                            <!--                    <el-button type="success" style="background-color: #326B30;border: none;" size="mini" @click="look_detail(item)">查看详情</el-button>-->
                        </div>
                    </div>
                </div>

            </div>
            <!--      <el-row class="el-row" :gutter="20" style="margin-top: 20px;" type="flex" justify="space-between">-->
            <!--        <div class="case-grid-content"  v-for="(item,index) in list"-->
            <!--                :key="index" @click="look_detail(item)">-->
            <!--          <div class="case-grid-item-bg">-->
            <!--            <img class="case-img" :src="imgUrl + item.thumbnail"/>-->

            <!--            <div class="case-grid-item-title">{{item.title}}</div>-->
            <!--            <div class="case-grid-item-title2">{{item.introduction}}</div>-->
            <!--          </div>-->
            <!--        </div>-->
            <!--      </el-row>-->
        </div>
    </div>
</template>

<script>
  import {getCase} from "@/api/case";

  export default {
        data() {
            return {
                list: [0, 1, 2, 3, 4, 5, 6, 7, 8],
            };
        },
        created() {
            this.getCaseList();
        },
        methods: {
            // 查看详情
            look_detail(item) {
                this.$router.push({path: "/caseDetail", query: {id: item.id}});
            },
            // 列表
            getCaseList() {
                getCase().then((res) => {
                    this.list = res.data;
                });
            },
        },
    };
</script>

<style>
    .case-bg {
        background: #ffffff;
    }

    .case_title {
        border-left: 3px solid #326b30;
        padding-left: 20px;
        margin-bottom: 50px;
    }

    .caseListing {
        width: 100%;
        display: flex;
        /*justify-content: space-between;*/
        align-items: center;
        flex-wrap: wrap;
    }

    .caseList_con {
        width: 33.3%;
        padding: 0 15px;
        box-sizing: border-box;
    }

    .caseBox {
        width: 100%;
        /*height: 500px;*/
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        background-color: #f9f9f9;
        /* padding-bottom: 20px; */
        transition: all 0.3s linear;
        cursor: pointer;
    }

    .caseBox:hover {
        transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: translateY(-6px);
        /*box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);*/
        border-radius: 8px;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }

    .caseBox:hover .goCase span {
        color: #ffffff;
        background-color: #326b30;
        border: 1px solid transparent;
    }

    /* .goCase span:hover {
      color: #ffffff;
      background-color: #326b30;
      border: 1px solid transparent;
    } */
    .caseBox_img {
        width: 100%;
    }

    .caseBox_img img {
        width: 100%;
        height: 295px;
        height: 315px;
    }

    .caseText {
        width: 100%;
    }

    .caseText p:nth-child(1) {
        font-size: 26px;
        font-weight: bolder;
        text-align: center;
        color: #333333;
        padding: 23px 0;
    }

    .caseText p:nth-child(2) {
        padding: 0 10px;
        font-size: 18px;
        /*font-weight: bolder;*/
        text-align: center;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

    .goCase {
        width: 100%;
        padding: 10px 0;
        padding-top: 23px;
        padding-bottom: 32px;
        text-align: center;
    }

    .goCase span {
        display: inline-block;
        width: 80px;
        height: 30px;
        line-height: 30px;
        color: #d3d3d3;
        /* background-color: #ffffff; */
        border: 1px solid #d3d3d3;
        transition: all 0.3s linear;

    }

    .case-img {
        width: 100%;
        height: 300px;
    }

    .case-grid-content {
        width: 30%;
        height: 520px;
        margin-top: 40px;
        overflow: hidden;
        cursor: pointer;
    }

    .case-grid-item-bg {
        height: 520px;
        background: #f9f9f9;
    }

    .case-grid-item-title {
        margin: 20px 18px;
        font-size: 26px;
        font-weight: 500;
        color: #333333;
    }

    .case-grid-item-title2 {
        margin-right: 18px;
        margin-left: 18px;
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
