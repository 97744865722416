<template>
    <div>
        <div class="details_head">
            <JoinUsHeader />
        </div>

        <div class="details-bg">
            <div class="navDetail" id="middle">
                <div class="navBox">
                    <ul>
                        <li>
                            <span>首页</span>
                        </li>
                        <li>
                            <span>></span>
                        </li>
                        <li>
                            <span @click="goJoinUs">加入我们</span>
                        </li>
                        <li>
                            <span>></span>
                        </li>
                        <li>
                            <span>选择岗位</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="search_box">
                <div class="search_con">
                    <el-autocomplete
                        class="inline-input"
                        v-model="searchVal"
                        :fetch-suggestions="querySearch"
                        placeholder="搜索岗位"
                        :trigger-on-focus="false"
                        @select="handleSelect"
                        clearable
                        style="width: 100%"
                    ></el-autocomplete>
                </div>
            </div>
            <div class="post_box">
                <div class="right_post">
                    <div class="r_p_top">
                        <div>
                            <span>筛选</span>
                        </div>
                        <div style="color: #326B30;font-weight: bolder">
                            <span @click="reset">重置</span>
                        </div>
                    </div>
                    <div class="r_p_foot">
                        <el-tree :data="data" :props="defaultProps" show-checkbox @node-click="handleNodeClick" @check-change="handleCheckChange"></el-tree>
                    </div>
                </div>
                <div class="left_post">
                    <div class="post_title">
                        <span>请选择你心仪的岗位</span>
                    </div>
                    <div class="post_foot">
                        <div class="p_f_con" @click="selectNav(19)">
                            <div class="post_title_top">
                                Web前端
                            </div>
                            <div class="post_brief_foot">
                                1.参与公司各项目中的前端需求变现，负责web前端、移动APP应用前端设计和研发工作；
                                <br/>
                                2.与后端人员配合，高质量完成前端开发
                            </div>
                        </div>
                    </div>
                    <div class="paginationBox">
                        <el-pagination layout="prev, pager, next" :total="total"></el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import Footer from '@/components/footer'
    import JoinUsHeader from "@/components/header/joinUsHeader";
    // import { detail } from "@/api/joinUs";

    export default {
        components: {
            Footer,
            JoinUsHeader,
        },
        data() {
            return {
                title:'',
                content:'',
                list: [
                    "1.参与公司各项目中的前端需求变现，负责web前端、移动APP应用前端设计和研发工作；",
                    "2.与后端人员配合，高质量完成前端开发工作；",
                    "1.根据项目需求，分析并提出合理的页面前端结构解决方案;",
                ],
                list2: [
                    "1.专科及以上，专业不限；",
                    " 2.良好的沟通协调能力，有一定的文字功底； ",
                    "3.精通HTML5/CSS3/Javascript等主",
                    "4精通jQuery、bootstrip等主流的js框架和库，并能够对其特性和应用有深入的了解；",
                    "5.熟悉ajax/xml/json等网络通信技术和数据交换格式；",
                    "6.熟悉Uni-APP框架，git工具；",
                ],
                searchVal:'',

                data: [{
                    label: '研 发',
                    children: [{
                        label: '二级 1-1',
                    },{
                        label: '二级 1-2',
                    }]
                }, {
                    label: '销 售',
                    children: [{
                        label: '二级 2-1',
                    }, {
                        label: '二级 2-2',
                    }]
                }, {
                    label: '职 能',
                    children: [{
                        label: '二级 3-1',
                    }, {
                        label: '二级 3-2',
                    }]
                }],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },

                //
                total: 0,
            };
        },
        created() {
            // this.getDetail(this.$route.query.id)
            this.goPosition(this.$route.query.middle,this.$route.query.id);
        },
        methods:{
            // 跳转定位
            goPosition(middle) {
                if (middle) {
                    this.$nextTick(() => {
                        document.querySelector("#" + middle).scrollIntoView(true);
                    });
                }
            },
            // 岗位申请
            goJoinUs(){
                // this.$router.push({path:"/joinUs",query:{apply:'apply',id:this.$route.query.id}})
                this.$router.push({path:"/joinUs",query:{apply:'apply'}})
            },
            // getDetail(id){
                // let data = {
                //     id:id
                // }
                // detail(data).then(res=>{
                //     this.title = res.data.title
                //     this.content = res.data.content
                // })
            // },
            querySearch(queryString, cb) {
                var restaurants = this.restaurants;
                // console.log(restaurants)
                var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createFilter(queryString) {
                // console.log("你要的",queryString)
                return (restaurant) => {
                    return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            handleSelect(item) {
                console.log(item);
            },

            reset(){

            },
            handleNodeClick(data) {
                console.log(data);
            },
            handleCheckChange(data, checked, indeterminate) {
                console.log(data, checked, indeterminate);
            },

            selectNav(id) {
                this.$router.push({ path: "/details", query: { id: id } });
            },
        }
    };
</script>

<style lang="scss" scoped>
    .details_head{
        width: 100%;
        height: 900px;
        background: url("../../assets/img/joinUs/9.png") no-repeat center center;
        background-size: 100% 100%;
    }

    .details-bg {
        /*margin-top: 100px;*/
        background: #ffffff;
    }

    .details-line {
        width: 100%;
        margin: 0 10px 30px;
        height: 2px;
        background: #e2e2e2;
    }

    .details-button {
        margin-left: 20px;
        width: 158px;
        height: 46px;
        font-size: 20px;
        font-family: OPPOSans;
        font-weight: 400;
        color: white;
        border: 0;
        background: #064226;
        cursor: pointer;
    }
    .details-title {
        font-size: 28px;
        font-family: OPPOSans;
        font-weight: 500;
        color: #666666;
        line-height: 35px;
    }

    .details-content {
        font-size: 20px;
        font-family: OPPOSans;
        font-weight: 400;
        color: #666666;
        line-height: 35px;
    }

    .navDetail {
        display: flex;
        align-items: center;
        height: 60px;
        background: #e9e9e9;
        .navBox {
            width: 1200px;
            margin: auto;
            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 230px;
                font-size: 18px;
                color: #333333;
            }
        }
    }
    .search_box{
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        .search_con{
            width: 600px;
            height: 50px;
        }
    }
    .post_box{
        width: 1200px;
        height: 500px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .right_post{
            flex: 1;
            height: 100%;
            .r_p_top{
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                div{
                    padding: 0 20px;
                    font-size: 18px;
                }
            }
            .r_p_foot{
                padding: 0 20px;
            }
        }
        .left_post{
            flex: 2;
            height: 100%;
            padding: 0 20px;
            position: relative;
            .post_title{
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 32px;
                font-weight: bolder;
            }
            .post_foot{
                width: 100%;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                .p_f_con{
                    width: 100%;
                    height: 80px;
                    display: flex;
                    flex-direction: column;
                    .post_title_top{
                        width: 100%;
                        height: 30px;
                        line-height: 30px;
                    }
                    .post_brief_foot{
                        padding-top: 5px;
                        width: 100%;
                        height: 50px;
                    }
                }
            }
            .paginationBox{
                width: 100%;
                position: absolute;
                bottom: 0;
                text-align: center;
            }
        }
    }
</style>
