import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from "@/pages/main"
import Home from "@/pages/home/index"
import Case from "@/pages/case/index"
import JoinUs from "@/pages/JoinUs/index"
import middle from "@/pages/JoinUs/middle"
import details from "@/pages/JoinUs/details"
// import About from "@/pages/about/index"
// import News from "@/pages/news/index"
import NewDetails from "@/pages/news/details"
import Product from "@/pages/product/index"
import ProDetails from "@/pages/product/proDetails"
import CaseDetails from "@/pages/case/detail"




Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    component: Main,
    redirect: '/home',
    children: [{
      path: '/home',
      name: '首页',
      component: Home
    },
    //   {
    //   path: '/about',
    //   name: '关于震原',
    //   component: About
    // },
      {
      path: '/product',
      name: '产品中心',
      component: Product
    }, {
      path: '/case',
      name: ' 成功案例',
      component: Case
    },
    //   {
    //   path: '/news',
    //   name: '新闻动态',
    //   component: News
    // },
      {
      path: '/joinUs',
      name: '加入我们',
      component: JoinUs
    }],
  },{
    path: '/details',
    name: '招聘详情',
    component: details
  },{
    path: '/middle',
    name: '人才招聘',
    component: middle
  },{
    path: '/newDetails',
    name: '新闻详情',
    component: NewDetails
  }
  ,{
    path: '/proDetails',
    name: '产品详情',
    component: ProDetails
  },{
    path: '/caseDetail',
    name: 'caseDetail',
    component: CaseDetails
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: '/',
  routes
})

export default router
