<template>
    <div class="serviceWrap">
        <div class="serviceContent box">
            <div class="content_top">
                <div>
                    <span style="color: #ffffff;font-size: 38px;">震原生物为您提供3大核心服务</span>
                </div>
                <div style="margin-top: 24px;">
                    <span style="color: #ffffff;font-size: 22px;">用智能数字化方案帮您解决</span>
                </div>
            </div>
            <div class="content_foot">
                <div class="content_box">
                    <div class="box_top">
                        <img src="../../assets/img/home/service/1.png" alt="">
                    </div>
                    <div class="box_foot">
                        <div>
                            <span style="color: #ffffff;font-size: 22px;">农业行业的软件与设备研发</span>
                        </div>
                        <div>
                            <span style="color: #ffffff;font-size: 18px;">
                                为客户提供的产品与服务以技术领先、安全可靠的特点著称，在业内得到广泛应用。
                            </span>
                        </div>
                    </div>
                </div>
                <div class="content_box">
                    <div class="box_top">
                        <img src="../../assets/img/home/service/2.png" alt="">
                    </div>
                    <div class="box_foot">
                        <div>
                            <span style="color: #ffffff;font-size: 22px;">提供数字化解决方案</span>
                        </div>
                        <div>
                            <span style="color: #ffffff;font-size: 18px;">
                                智码物联，打通线上线下，全链路数字化管理，建立企业核心数据资产。
                            </span>
                        </div>
                    </div>
                </div>
                <div class="content_box">
                    <div class="box_top">
                        <img src="../../assets/img/home/service/3.png" alt="">
                    </div>
                    <div class="box_foot">
                        <div>
                            <span style="color: #ffffff;font-size: 22px;">36+动植物行业专业产品</span>
                        </div>
                        <div>
                            <span style="color: #ffffff;font-size: 18px;">
                                产品覆盖动植物检测设备、执法设备、病情防控设备、动植物防疫物资等。
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "service"
    }
</script>

<style scoped lang="scss">
    @import "../../assets/style/zy_websit.scss";
    .serviceWrap {
        background-color: #141b33;
        width: 100%;
        /*height: 606px;*/
        /*background: url("../../assets/img/home/service/service_bg.png") no-repeat center center;*/
        /*background-size: 100% 100%;*/
        .serviceContent{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            .content_top{
                text-align: center;
            }
            .content_foot{
                margin-top: 20px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap:wrap;
                .content_box{
                    width: 30%;
                    height: 360px;
                    /*background-color: #ffffff;*/
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    overflow: hidden;
                    .box_top{
                        flex: 0.7;
                        width: 100%;
                        overflow: hidden;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            width: 50%;
                            height: auto;
                        }
                    }
                    .box_foot {
                        box-sizing: border-box;
                        flex: 0.3;
                        width: 100%;
                        text-align: center;
                        /*padding: 20px 0;*/
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        div{
                            &:nth-child(1){
                                padding-top: 20px;
                            }
                        }
                    }
                }
            }
        }

    }
</style>
