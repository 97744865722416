<template>
  <div class="footerWrap">
    <div class="footBox">
      <!-- 公司电话地址开始 -->
      <div class="addressWrap">
        <div class="addressTitle">
          <span>联系我们</span>
        </div>
        <div class="address">
          <span>地址：福建省福州市鼓楼区湖东路99号标力大厦14楼1401室</span>
        </div>
        <div>
          <span>电话：0591-87323113</span>
        </div>
      </div>
      <!-- 公司电话地址结束 -->

      <!-- 导航栏开始 -->
      <div class="nav">
        <ul>
<!--          <li>-->
<!--            <div class="fTitle">-->
<!--              <span>关于震原</span>-->
<!--            </div>-->
<!--            <div class="ftItem">-->
<!--              <span><router-link :to="{path:'about',query:{params:'about'}}">企业简介</router-link></span>-->
<!--              <span><router-link :to="{path:'about',query:{params:'culture'}}">企业文化</router-link> </span>-->
<!--              <span><router-link :to="{path:'about',query:{params:'knowledge'}}">知识产权</router-link></span>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li>-->
<!--            <div class="fTitle">-->
<!--              <span>产品中心</span>-->
<!--            </div>-->
<!--            <div class="ftItem">-->
<!--              <span><router-link :to="{path:'product',query:{navIndex:0,params:'product'}}">动植物防疫物资</router-link></span>-->
<!--              <span><router-link :to="{path:'product',query:{navIndex:1,params:'product'}}">动植物疫病防控</router-link></span>-->
<!--              <span><router-link :to="{path:'product',query:{navIndex:2,params:'product'}}">动植物执法设备</router-link></span>-->
<!--              <span><router-link :to="{path:'product',query:{navIndex:3,params:'product'}}">农畜产品检测</router-link></span>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li>-->
<!--            <div class="fTitle">-->
<!--              <span>成功案例</span>-->
<!--            </div>-->
<!--            <div class="ftItem">-->
<!--              <span><router-link :to="{path:'caseDetail',query:{id:4}}">“码”上农安</router-link></span>-->
<!--              <span><router-link :to="{path:'caseDetail',query:{id:2}}">边检站消毒通道</router-link></span>-->
<!--              <span><router-link :to="{path:'caseDetail',query:{id:1}}">动物卫生监督</router-link></span>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li>-->
<!--            <div class="fTitle">-->
<!--              <span>新闻动态</span>-->
<!--            </div>-->
<!--            <div class="ftItem">-->
<!--              <span><router-link :to="{path:'news',query:{params:'new'}}">最新动态</router-link></span>-->
<!--              <span><router-link :to="{path:'news',query:{params:'new'}}">全部新闻</router-link></span>-->
<!--            </div>-->
<!--          </li>-->
<!--          <li>-->
<!--            <div class="fTitle">-->
<!--              <span>加入我们</span>-->
<!--            </div>-->
<!--            <div class="ftItem">-->
<!--              <span><router-link :to="{path:'joinUs',query:{params:'person'}}">人才招聘</router-link></span>-->
<!--              <span><router-link :to="{path:'joinUs',query:{params:'joinUs'}}">加入我们</router-link></span>-->
<!--            </div>-->
<!--          </li>-->
          <li>
            <div class="fTitle">
              <img src="../../assets/img/footer/code.png" alt="" />
            </div>
            <div class="ftItemImg">
              <span>关注震原生物</span>
              <span>公众号</span>
            </div>
          </li>
        </ul>
      </div>
      <!-- 导航栏结束 -->
    </div>

    <div class="internetWrap">
      <div class="internetBox">
        <div>
          <span
            >版权所有： CopyRight 2021, 福建省震原生物技术有限公司, Inc.All
            Rights Reserved.</span
          >
        </div>
        <div>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35010402351264"
            target="_blank"
            ><span
              ><img src="../../assets/img/footer/1.png" alt="" />
              闽公网安备35010402351264号</span
            ></a
          >
        </div>
        <div>
          <a href="https://beian.miit.gov.cn" target="_blank"
            ><span>闽ICP备2021012937号</span></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footerWrap {
  background: #333333;
  .footBox {
    width: 1200px;
    margin: auto;
    border: 1px solid #333;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .nav {
      flex: 1;
      font-size: 20px;
      color: #ffffff;
      margin-top: 30px;
      ul {
        display: flex;
        justify-content: flex-end;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          .fTitle {
            width: 180px;
            height: 180px;
            font-size: 20px;
            color: #ffffff;
            margin-bottom: 10px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .ftItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 18px;
            color: #d3d3d3;
            span {
              margin: 7px 0;
            }
          }
          .ftItemImg {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 18px;
            color: #d3d3d3;
          }
        }

        li {
          span:hover {
            color: #fff;
          }
        }
      }
    }

    .addressWrap {
      flex: 1;
      font-size: 18px;
      color: #ffffff;
      .addressTitle{
        font-size: 28px;
      }
      .address {
        padding: 15px 0;
      }
    }
  }
  .internetWrap {
    margin-top: 35px;
    background: #222222;
    .internetBox {
      display: flex;
      justify-content: space-between;
      padding: 15px 0;
      width: 1200px;
      margin: auto;
      font-size: 16px;
      color: #d3d3d3;
      span {
        display: flex;
        align-items: center;
        color: #d3d3d3;

        img {
          margin-right: 10px;
        }
      }
      span:hover {
        color: #fff;
      }
    }
  }
}
</style>

